import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Guacamole from "guacamole-common-js";
import $ from "jquery";
import {
  DeviceConnectionProfileForm,
  DeviceDetails,
  UsernameAndPasswordForm,
} from "pages/devices/types";
import apiClient from "common/apiClientAxios";
import { DeviceAccess } from "common/types";
import { useDispatch } from "react-redux";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { deviceAccessErrors } from "pages/devices/device-access-errors";
import RDPLoginForm from "./RDPLoginForm";
import { constants } from "common/constants";
import {
  getGateEndTimeAndCountDownEndTime,
  isAdminUser,
  isEndUser,
} from "common/helpers/utils";
import { Protocol } from "common/enums";
import CountdownDialog from "common/components/CountDownDialog";
import AdminAccessCountdown from "./AdminAccessCountDown";

interface RemoteAccessVNCProps {
  open: boolean;
  onClose: () => void;
  device: DeviceDetails;
  isHideDialog?: boolean;
}
const RemoteAccessVNC: React.FC<RemoteAccessVNCProps> = ({
  open,
  onClose,
  device,
  isHideDialog = false,
}) => {
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [gateId, setGateId] = useState(-1);
  const [inboundPort, setInboundPort] = useState(-1);
  const [guacamoleKeyboard] = useState<Guacamole.Keyboard>(
    new Guacamole.Keyboard(document)
  );
  const [showUsernamePasswordForm, setShowUsernamePasswordForm] =
    useState(false);
  const [showReconnectForm, setShowReconnectForm] = useState(false);
  const [hasAuthProfile] = useState(
    Boolean(device.accessProfile?.username && device.accessProfile?.password)
  );
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const accessEndTime = getGateEndTimeAndCountDownEndTime(
    device.accessProfile?.accessEndTime ?? 0
  );
  const [countDownOpen, setCountDownOpen] = useState<boolean>(false);
  const onSubmitCredentials = async (data: UsernameAndPasswordForm) => {
    setErrorMessage("");
    setShowUsernamePasswordForm(false);
    const isReconnect =
      guacamoleClientState === 5 || guacamoleTunnelState === 2;
    const accessProfile = {
      ...device.accessProfile,
      name: device.accessProfile?.name ?? "",
      username: data.username,
      password: data.password,
    } as DeviceConnectionProfileForm;
    await getToken(
      {
        connectionProfile: accessProfile,
        type: Protocol.HTTP_S,
        endTime: accessEndTime.endTime,
        siteId: device.siteId,
        siteName: device.siteName,
      },
      isReconnect,
      inboundPort
    );
  };

  const connectToDevice = useCallback(
    (token: string, webSocketBaseURL: string) => {
      window.setTimeout(() => {
        const display = document.getElementById("VNCdisplay");
        const container = document.getElementById("container");
        const width = container?.clientWidth
          ? container?.clientWidth - constants.CANVAS_WIDTH_OFFSET
          : constants.DIALOG_CANVAS_WIDTH;
        const height = container?.clientHeight
          ? container?.clientHeight - constants.CANVAS_HEIGHT_OFFSET
          : constants.DIALOG_CANVAS_HEIGHT;
        if (display && token) {
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}&width=${width}&height=${height}&`
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();

          // Add client to display div
          const canvas = $(displayElement).find(`canvas`);
          canvas.css("z-index", "2");
          display?.appendChild(displayElement);

          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onEach(["mousedown", "mousemove", "mouseup"], () => {
            guac.sendMouseState(mouse.currentState);
          });
          // Keyboard
          guacamoleKeyboard.onkeydown = function (keysym) {
            guac.sendKeyEvent(1, keysym);
          };
          guacamoleKeyboard.onkeyup = function (keysym) {
            guac.sendKeyEvent(0, keysym);
          };

          guac.onstatechange = (state: Guacamole.Client.State) => {
            console.log("Guacamole Client State =", state);
            setGuacamoleClientState(state);
            if (state === 5) {
              if (guacamoleKeyboard) {
                guacamoleKeyboard.onkeyup = null;
                guacamoleKeyboard.onkeydown = null;
              }
              hasAuthProfile
                ? setShowReconnectForm(true)
                : setShowUsernamePasswordForm(true);
              handleDisconnect();
            }
            if (state === 3) {
              setCountDownOpen(true);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            console.log("Guacamole Guacamole State =", state);
            setGuacamoleTunnelState(state);
            if (state === 2) {
              if (guacamoleKeyboard) {
                guacamoleKeyboard.onkeyup = null;
                guacamoleKeyboard.onkeydown = null;
              }
              hasAuthProfile
                ? setShowReconnectForm(true)
                : setShowUsernamePasswordForm(true);
              handleDisconnect();
            }
          };
          const handleDisconnect = () => {
            if (display.hasChildNodes()) display.replaceChildren();
            guacTunnel?.state === Guacamole.Tunnel.State.OPEN &&
              guac.disconnect();
            guacTunnel?.state === Guacamole.Tunnel.State.OPEN &&
              guacTunnel.disconnect();
          };
          const handleError = (error: Guacamole.Status) => {
            setErrorMessage(
              deviceAccessErrors[error.code] ?? "Something went wrong..."
            );
            handleDisconnect();
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect("test");
          // Disconnect on close
          window.onunload = function () {
            handleDisconnect();
          };
        }
      }, 1000);
    },
    [guacamoleKeyboard, hasAuthProfile]
  );

  const getToken = useCallback(
    async (connectionSettings: DeviceAccess, isReconnect: boolean) => {
      try {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: true,
          })
        );
        const api = isReconnect
          ? `devices/${device.deviceId}/getReconnectToken`
          : `devices/${device.deviceId}/getToken`;
        const tokenResponse = await apiClient.post(api, {
          ...connectionSettings,
        });
        const tokenResponseData = tokenResponse.data.data;
        if (
          tokenResponseData.sessionResponse &&
          tokenResponseData.sessionResponse.status === "error"
        ) {
          const userDetails = tokenResponseData.sessionResponse?.userDetails;
          const userName =
            userDetails?.firstName && userDetails?.lastName
              ? `${userDetails?.firstName} ${userDetails?.lastName}`
              : "Other user";
          setErrorMessage(
            `Available sessions are full. ${userName} is accessing device.`
          );
          dispatch(
            setLoader({
              loaderMessage: "Please wait connecting...",
              openLoader: false,
            })
          );
          dispatch(
            setSnackbarToast({
              message: `Available sessions are full. ${userName} is accessing device.`,
              open: true,
              severity: "error",
            })
          );
          return;
        }
        const token = tokenResponseData.token;
        if (!isReconnect) {
          //Set opened gate id
          setGateId(tokenResponseData.gateId ?? -1);
          setInboundPort(tokenResponseData.inboundPort ?? -1);
        }
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        //connect to guacamole server
        const webSocketBaseURL =
          process.env.REACT_APP_USE_LOCAL_WEB_SOCKET_URL?.toLowerCase() ===
          "yes"
            ? process.env.REACT_APP_WEB_SOCKET_URL ??
              constants.DEFAULT_WEB_SOCKET_URL
            : `${process.env.REACT_APP_WEB_SOCKET_PROTOCOL ?? "ws"}://${
                connectionSettings.siteName
              }-rc:${constants.DEFAULT_WEB_SOCKET_PORT}`;
        connectToDevice(token, webSocketBaseURL);
        //for https connection type, after gaucamole connection call api to setConfig https agent
        await apiClient.post(`/devices/configHttpsAgent`, {
          connectionProfile: connectionSettings.connectionProfile,
          siteId: connectionSettings.siteId,
        });
        return token;
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        setErrorMessage(errorData);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: "error",
          })
        );
        //if dialog then close
        if (!isHideDialog) {
          onClose();
        }
      }
    },
    [connectToDevice, device.deviceId, dispatch, isHideDialog, onClose]
  );

  const closeConnection = useCallback(
    async (gateId?: number, inboundPort?: number) => {
      if (gateId !== -1) {
        try {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: true,
            })
          );
          await apiClient.post(`devices/${device.deviceId}/closeConnection`, {
            siteId: device.siteId,
            gateId: gateId,
            inboundPort: inboundPort,
            type: Protocol.HTTP_S,
            connectionProfile: device.accessProfile,
          });
          setGateId(-1);
          setInboundPort(-1);
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
        } catch (error: any) {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: "error",
            })
          );
        }
      }
    },
    [device.accessProfile, device.deviceId, device.siteId, dispatch]
  );

  const onDisconnect = async () => {
    if (guacamoleKeyboard && guacamoleKeyboard.onkeyup) {
      guacamoleKeyboard.onkeyup = null;
      guacamoleKeyboard.onkeydown = null;
    }
    guacamoleTunnelState === Guacamole.Tunnel.State.OPEN &&
      guacamoleClient?.disconnect();
    await closeConnection(gateId, inboundPort);
    window.setTimeout(() => onClose(), 100);
  };

  React.useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      try {
        if (guacamoleKeyboard && guacamoleKeyboard.onkeyup) {
          guacamoleKeyboard.onkeyup = null;
          guacamoleKeyboard.onkeydown = null;
        }
        guacamoleTunnelState === Guacamole.Tunnel.State.OPEN &&
          guacamoleClient?.disconnect();
        await closeConnection(gateId, inboundPort);
      } catch (error) {
        console.error("Error while closing gate:", error);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    closeConnection,
    gateId,
    guacamoleClient,
    guacamoleKeyboard,
    inboundPort,
    guacamoleTunnelState,
  ]);

  const reConnectWithAuthProfile = async () => {
    if (device.accessProfile) {
      setShowReconnectForm(false);
      await getToken(
        {
          connectionProfile: device.accessProfile,
          type: Protocol.HTTP_S,
          endTime: accessEndTime.endTime,
          siteId: device.siteId,
          siteName: device.siteName,
        },
        true
      );
    }
  };

  const getTokenWithAuthProfile = useCallback(
    async (accessProfile: DeviceConnectionProfileForm) => {
      await getToken(
        {
          connectionProfile: accessProfile,
          type: Protocol.HTTP_S,
          endTime: accessEndTime.endTime,
          siteId: device.siteId,
          siteName: device.siteName,
        },
        false
      );
    },
    [accessEndTime, device.siteId, getToken, device.siteName]
  );
  const isRunEffect = useRef(true);
  React.useEffect(() => {
    if (isRunEffect.current) {
      isRunEffect.current = false;
      if (device.accessProfile?.username && device.accessProfile?.password) {
        getTokenWithAuthProfile(device.accessProfile);
      } else {
        setShowUsernamePasswordForm(true);
      }
    }
  }, [device.accessProfile, getTokenWithAuthProfile]);

  const handleCloseCountDownPopup = () => {
    setCountDownOpen(false);
  };

  const handleTimeUp = () => {
    setCountDownOpen(true);
  };

  return isHideDialog ? (
    <Card sx={{ overflow: "auto", minHeight: "100vh" }}>
      <CardHeader
        sx={{ paddingTop: 1, paddingBottom: 0 }}
        titleTypographyProps={{ textAlign: "center" }}
        title={
          <>
            <Typography component="span" sx={{ fontSize: 16 }}>
              {`Connect to device: '${device.name}' URL: ${
                device.accessProfile?.deviceIP ??
                device.accessProfile?.urls?.[0] ??
                ""
              } using ${device.accessProfile?.name} account`}
            </Typography>
            <br />
            {isAdminUser() ? (
              <AdminAccessCountdown deviceTimezone={device.timezone} />
            ) : (
              <CountdownDialog
                open={false}
                countDownTime={accessEndTime.countDownTime}
                handleDisconnectOkayClick={onDisconnect}
                onClose={handleCloseCountDownPopup}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
                isShowOnlyCountdown={guacamoleClientState === 3}
              ></CountdownDialog>
            )}
          </>
        }
        action={
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={onDisconnect}
          >
            {"Close"}
          </Button>
        }
      />
      <CardContent sx={{ minHeight: "100vh" }} id="container">
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {/* Error Message */}
            {(guacamoleClientState === 5 ||
              guacamoleTunnelState === 2 ||
              errorMessage) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage ?? "Something went wrong..."}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {!hasAuthProfile && showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}
              ></RDPLoginForm>
            )}
            {hasAuthProfile && showReconnectForm && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => reConnectWithAuthProfile()}
                  >
                    {"ReConnect"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={onDisconnect}
                    id="cancel"
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <div id="VNCdisplay"></div>
            {isEndUser() && (
              <CountdownDialog
                open={countDownOpen}
                countDownTime={accessEndTime.countDownTime}
                onClose={handleCloseCountDownPopup}
                handleDisconnectOkayClick={onDisconnect}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
              ></CountdownDialog>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          minHeight: "90vh",
          minWidth: "80vw",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1, pb: 1.5 }} textAlign={"center"}>
        <Typography component="span" sx={{ fontSize: 16 }}>
          {`Connect to device: '${device.name}'  ${
            device.accessProfile?.deviceIP
              ? `IP: ${device.accessProfile?.deviceIP}`
              : `URL: ${device.accessProfile?.urls?.[0]}`
          } 
          using ${device.accessProfile?.name} account`}
        </Typography>
        <br />
        {isAdminUser() ? (
          <AdminAccessCountdown deviceTimezone={device.timezone} />
        ) : (
          <CountdownDialog
            open={false}
            countDownTime={accessEndTime.countDownTime}
            handleDisconnectOkayClick={onDisconnect}
            onClose={handleCloseCountDownPopup}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}
            isShowOnlyCountdown={guacamoleClientState === 3}
          ></CountdownDialog>
        )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id="container">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: "100%" }}
        >
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {/* Error Message */}
            {(guacamoleClientState === 5 ||
              guacamoleTunnelState === 2 ||
              errorMessage) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage ?? "Something went wrong..."}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {!hasAuthProfile && showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}
              ></RDPLoginForm>
            )}
            {hasAuthProfile && showReconnectForm && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => reConnectWithAuthProfile()}
                  >
                    {"ReConnect"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={onDisconnect}
                    id="cancel"
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <div id="VNCdisplay"></div>
            {isEndUser() && (
              <CountdownDialog
                open={countDownOpen}
                countDownTime={accessEndTime.countDownTime}
                onClose={handleCloseCountDownPopup}
                handleDisconnectOkayClick={onDisconnect}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
              ></CountdownDialog>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={onDisconnect}
        >
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoteAccessVNC;
